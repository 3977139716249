<template>
	<div v-if="this.tableData.length>0" id="Blog" :class="isIOS()?'':'backgroundFixed'">
		<div class="container" >
			<a name="Blog"></a>
			<div class="main-title" data-aos='fade-down'>
				Blog
			</div>
			<ul class="areasInterest">
				<li v-for="item in tableData" :key="item.id" class="flex" :style="{backgroundImage:`url('${item.articlePic}')`}" @click="goLink(item)">
					<div>
						<div class="h3">{{item.articleTitle}}</div>
						<div class="h4">{{item.articleDesc}}</div>
						<!-- <div class="time">{{item.publishTime}}</div> -->
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import service from '../utils/request.js';
	export default {
		name: "Identity",
		components: {
			
		},
		data() {
			return {
				ac:'first',
				tableData:[],
			};
		},
		props: {
			
		},
		mounted() {
			service.get(`/business/article/list?pageNum=1&pageSize=100`).then(res => {
				if(res.data.code == 200){
					this.tableData  = res.data.rows;
				}
			}).catch(err => {
				
			});
		},
		methods: {
			isIOS(){
			    var u = navigator.userAgent;
			    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			    return isiOS;
			},
			goLink(item){
				if(item.articleUrl){
					window.open(item.articleUrl);
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	
	#Blog {
	    width: 100%;
		padding-bottom: 30px;
	    // min-height: 100vh;
	    // background:#fff url('../assets/bg/AreasOfInterest-bg.png') no-repeat center center;
	    // background-size: cover;
	    display: flex;
	    align-items: center;
		.title{
			color:rgba(255, 190, 105, 1);
			font-size: 5rem;
			font-family: Stingray;
		}
		.areasInterest{
			margin-top: 30px;
			display: flex;
			justify-content:flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			li{
				height:160px;
				flex: 0 0 32%;
				margin-right: calc(4% / 2);
				margin-bottom: 20px;
				justify-content: flex-start;
				align-items: flex-end;
				padding: 5px;
				background-size:cover;
				background-repeat: no-repeat;
				background-position:center;
				cursor:pointer;
				border-radius: 8px;
				&:nth-child(3n){
					margin-right: 0;
				}
				.h3{
					color: #fff;
					font-size: 1rem;
					font-weight: 500;
				}
				.h4{
					color: #cbcbcb;
					font-size: 0.8rem;
					margin-bottom:0;
				}
				.time{
					color: #fff;
					font-size: 0.9rem;
				}
				.read{
					cursor: pointer;
					a{
						color:#fff ;
						text-decoration: none;
					}
				}
			}
		}
	}
	
	@media (max-width: 768px) {
		#Blog{
			.title{
				font-size: 2rem;
			}
			.areasInterest{
				li{
					flex: 0 0 100%;
					margin-right:0;
				}
			}
		}
	}
	
	@media (min-width: 768px) and (max-width: 1168px) {
		.container{
			max-width: 720px;
		}
	}
</style>
