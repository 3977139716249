<template>
	<div v-if="tableData.length>0" id="Partners" :class="isIOS()?'':'backgroundFixed'">
		<div class="container" >
			<a name="PARTNERS"></a>
			<div class="title" data-aos='fade-down'>
				PARTNERS
			</div>
			<div class="subtitle" data-aos='fade-down' data-aos-delay='200'>
				
			</div>
			<ul class="areasInterest" >
				<li v-for="item in tableData" :key="item.id">
					<a :href="`${item.portUrl?item.portUrl:'javascript:;'}`" :target="`${item.portUrl?'_blank':''}`">
						<img :src="`${item.pic}`" >
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import service from '../utils/request.js';
	export default {
		name: "Partners",
		components: {
			
		},
		data() {
			return {
				ac:'first',
				tableData:[]
			};
		},
		props: {
			
		},
		mounted() {
			service.get(`/business/partners/list?pageNum=1&pageSize=100`).then(res => {
				if(res.data.code == 200){
					res.data.rows.forEach((item,index)=>{
						if(item.isShow == 1){
							this.tableData.push(item);
						}
					})
				}
			}).catch(err => {
				
			});
		},
		methods: {
			isIOS(){
			    var u = navigator.userAgent;
			    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			    return isiOS;
			},
		}
	}
</script>

<style lang="scss" scoped>
	
	#Partners {
	    width: 100%;
	    // min-height: 100vh;
	    // background:#fff url('../assets/bg/AreasOfInterest-bg.png') no-repeat center center;
	    // background-size: cover;
	    display: flex;
	    align-items: center;
		.title{
			color:#696969;
			font-size: 2.5em;
			font-family: MiSans-Medium;
		}
		.subtitle{
			color: rgba(166, 114, 71, 1);
			font-size: 1.5rem;
			margin: 3rem 0;
		}
		
		.areasInterest{
			display: flex;
			flex-wrap: wrap;
			margin-top: 50px;
			li{
				margin-right: calc(5% /4);
				flex: 0 0 19%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				margin-bottom: 50px;
				cursor: pointer;
				padding: 0.5rem 2rem;
				&:nth-child(5n){
					margin-right: 0;
				}
				img{
					width: 100%;
					// transition:  all 0.3s linear;
				}
				.h3{
					margin-top: 10px;
					color: rgba(33, 33, 33, 1);
					font-size: 1.3rem;
					font-family: MiSans-Medium;
				}
				.h4{
					margin-top: 10px;
					color: rgba(128, 128, 128, 1);
					font-size: 1rem;
				}
				&:hover img{
					// transform: translateY(-10px) scale(1.1);
				}
			}
		}
	}
	
	@media (max-width: 768px) {
		#Partners{
			.title{
				font-size: 2rem;
			}
			.subtitle{
				font-size: 1.2rem;
			}
			.areasInterest{
				li{
					flex: 0 0 48%;
				}
			}
			.invest{
				padding: 1rem;
				.line-bg{
					display: none;
				}
				.info{
					position: initial;
					width: auto;
					display: flex;
					justify-content: flex-start;
					flex-direction: column;
					margin-bottom: 20px;
					.info-title{
						position: initial;
					}
					.info-introduce{
						position: initial;
						opacity: 1 !important;
						transform: translateY(0) !important;
						.line{
							display: none;
						}
					}
				}
			}
		}
	}
	
	@media (min-width: 768px) and (max-width: 1168px) {
		.container{
			max-width: 720px;
		}
	}
</style>
