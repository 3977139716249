<template>
	<div v-if="this.tableData.length>0" id="Funding" :class="isIOS()?'':'backgroundFixed'" :style="{backgroundImage:`url('${this.tableData[0].pic}')`}">
		<div class="intro-container">
			<div class="intro-headline">
				<h1 data-aos='fade-down' data-aos-delay='300'>
					{{this.tableData[0].pageTitle}}
				</h1>
				<h2 data-aos='fade-down' data-aos-delay='320'>
					{{this.tableData[0].pageDesc}}
				</h2>
				<a href="/Apply" class="button">
					Apply For Fund
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import service from '../utils/request.js';
	export default {
		name: "Funding",
		components: {
			
		},
		data() {
			return {
				tableData:[]
			};
		},
		props: {
			
		},
		mounted() {
			service.get(`/business/page/list`).then(res => {
				if(res.data.code == 200){
					this.tableData  = res.data.rows;
				}
			}).catch(err => {
				
			});
		},
		methods: {
			isIOS(){
			    var u = navigator.userAgent;
			    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			    return isiOS;
			},
		}
	}
</script>

<style lang="scss" scoped>
	
	#Funding {
	    width: 100%;
	    min-height: 100vh;
	    // background: url('../assets/bg/home-bg.png') no-repeat center center;
		background-position: center center;
		background-repeat: no-repeat;
	    background-size: cover;
		
		.intro-container {
			width: 45%;
		    height: 100%;
		    position: absolute;
		    left: 11.11111%;
		    display: -ms-flexbox;
		    display: flex;
		    -ms-flex-align: center;
		    align-items: center;
			.intro-headline{
				letter-spacing: -0.042em;
				h1{
					font-size: 5rem;
					color: #fff;
					font-family: MiSans-Bold;
				}
				h2{
					color: #c5c5c5;
					width: 77.777777778%;
					font-size: 1.6rem;
					margin:1rem 0 3rem 0;
				}
			}
		}
		
	}
	
	@media (max-width: 768px) {
		#Funding {
			background-size: contain;
			.intro-container {
				width: 80%;
				margin: 0 auto;
				.intro-headline{
					h1{
						font-size: 2.5rem;
					}
					h2{
						width: 100%;
						font-size: 1.4rem;
					}
				}
			}
		}
	}
	
	@media (min-width: 768px) and (max-width: 1168px) {
		#Funding {
			.intro-container {
				.intro-headline{
					h1{
						font-size: 5rem;
					}
					h2{
						font-size: 2rem;
					}
				}
			}
		}
	}
</style>
