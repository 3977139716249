<template>
	<div id="Footer">
		<div class="footer">
			<div class="container">
				<div class="flex" style="justify-content: space-between;">
					<img class="logo" src="../assets/logo-white.png">
					<div class="flex">
						<ul>
							<li>
								<div class="flex" style="margin-bottom: 10px;">
									<a href="https://medium.com/@Dtcapital_8" target="_blank">
										<img class="logo" src="../assets/medium.png" style="width: 25px;">
									</a>
									<a href="https://twitter.com/Dtcapital_8" target="_blank" style="margin-right: 0;">
										<img class="logo" src="../assets/tw.png" style="width: 25px;">
									</a>
								</div>
							</li>
							<li>Privacy Policy</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom">© 2023 DoubleTree Capital. All Rights Reserved.</div>
	</div>
</template>

<script>
    export default {
        name: "Footer",
        props: {
            
        },
        data() {
            return {
                
            }
        },
        methods: {
            
        },
        components: {
			
		},
    }
</script>

<style lang="scss" scoped>
	#Footer{
		background: #242424;
	}
	.footer{
		padding: 5rem 0;
		// border-top: 1px solid rgba(166, 114, 71, 1);
		color:rgba(166, 166, 166, 1);
		font-size:1.2rem;
		a{
			margin-right: 2rem;
			img{
				width: 2rem;
			}
		}
		.logo{
			width: 18rem;
		}
	}
	.bottom{
		text-align: center;
		padding: 2rem 0;
		color: #fff;
	}
	@media (max-width: 768px) {
		.footer{
			padding:4rem 1rem;
			.container{
				a{
					font-size: 1rem;
					img{
						width: 1rem;
					}
				}
				>div{
					flex-direction: column;
					.logo{
						width: 20rem;
						margin: 20px 0;
					}
				}
			}
		}
		
	}
</style>