<template>
	<div v-if="this.tableData.length>0" id="PORTFOLIO" :class="isIOS()?'':'backgroundFixed'">
		<div class="container" >
			<a name="PORTFOLIO"></a>
			<div class="main-title" data-aos='fade-down'>
				{{this.tableData[0].abouteTitle}}
			</div>
			<div class="main-subtitle" data-aos='fade-down' data-aos-delay='200'>
				{{this.tableData[0].aboutDesc}}
			</div>
			<ul class="areasInterest">
				<li>
					<div class="h3">{{this.tableData[0].aboutP1Name}}</div>
					<div class="h4">{{this.tableData[0].aboutP1Desc}}</div>
					<div class="h4">{{this.tableData[0].aboutP1Msg}}</div>
				</li>
				<li>
					<div class="h3">{{this.tableData[0].aboutP2Name}}</div>
					<div class="h4">{{this.tableData[0].aboutP2Desc}}</div>
					<div class="h4">{{this.tableData[0].aboutP2Msg}}</div>
				</li>
				<li>
					<div class="h3">{{this.tableData[0].aboutP3Name}}</div>
					<div class="h4">{{this.tableData[0].aboutP3Desc}}</div>
					<div class="h4">{{this.tableData[0].aboutP3Msg}}</div>
				</li>
			</ul>
		</div>
		<div class="shadow"></div>
	</div>
</template>

<script>
	import service from '../utils/request.js';
	export default {
		name: "PORTFOLIO",
		components: {
			
		},
		data() {
			return {
				ac:'first',
				tableData:[]
			};
		},
		props: {
			
		},
		mounted() {
			service.get(`/business/portfolio/list`).then(res => {
				if(res.data.code == 200){
					this.tableData  = res.data.rows;
				}
			}).catch(err => {
				
			});
		},
		methods: {
			isIOS(){
			    var u = navigator.userAgent;
			    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			    return isiOS;
			}
		}
	}
</script>

<style lang="scss" scoped>
	
	#PORTFOLIO {
	    width: 100%;
	    // min-height: 100vh;
	    // background:#fff url('../assets/bg/AreasOfInterest-bg.png') no-repeat center center;
	    // background-size: cover;
	    display: flex;
	    align-items: center;
		position: relative;
		.title{
			color:rgba(255, 190, 105, 1);
			font-size: 5rem;
			font-family: Stingray;
		}
		.subtitle{
			color: rgba(166, 114, 71, 1);
			font-size: 1.5rem;
			margin: 3rem 0;
			font-family: BreeSerif-Regular;
		}
		
		.areasInterest{
			display: flex;
			justify-content:center;
			align-items: flex-start;
			flex-wrap: wrap;
			li{
				flex: 0 0 33%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				margin-bottom: 50px;
				img{
					width: 120px;
					padding: 20px;
					transition:  all 0.3s linear;
				}
				>div{
					margin-top: 10px;
					color: rgba(166, 114, 71, 1);
				}
				.h3{
					font-weight: 500;
					font-size: 3rem;
					color: #c9a646;
					font-family: MiSans-Medium;
				}
				.h4{
					text-align: center;
					font-size: 1.2rem;
					color: #fff;
				}
				&:hover img{
					transform: translateY(-10px) scale(1.1);
				}
			}
		}
		.shadow {
			  border-radius: 100px;
			  filter: blur(60px);
			  position: absolute;
			  height: 400px;
			  width: 400px;
			  background-color: rgba(201, 166, 71, 1);
			  top: 100px;
			  right: -350px;
		}
	}
	
	@media (max-width: 768px) {
		#PORTFOLIO{
			.title{
				font-size: 2rem;
			}
			.subtitle{
				font-size: 1.2rem;
			}
			.areasInterest{
				li{
					flex: 0 0 48%;
				}
			}
			.invest{
				padding: 1rem;
				.line-bg{
					display: none;
				}
				.info{
					position: initial;
					width: auto;
					display: flex;
					justify-content: flex-start;
					flex-direction: column;
					margin-bottom: 20px;
					.info-title{
						position: initial;
					}
					.info-introduce{
						position: initial;
						opacity: 1 !important;
						transform: translateY(0) !important;
						.line{
							display: none;
						}
					}
				}
			}
		}
	}
	
	@media (min-width: 768px) and (max-width: 1168px) {
		.container{
			max-width: 720px;
		}
	}
</style>
