import Vue from "vue";
import VueI18n from 'vue-i18n'  
Vue.use(VueI18n);

import zh from './modules/zh.js'
import en from './modules/en.js'

const i18n = new VueI18n({
	locale: localStorage.getItem('lang') || 'en', 
	messages: {
		zh: zh, 
		en: en  
	},
})

export default i18n;

