<template>
    <div>
		<nav class="navbar navbar-expand-md navbar-expand" :class="fixed?'fixed-top':'top'">
		    <div class="container-fluid" style="padding: 0rem 3rem 0 0;">
		        <a class="navbar-brand" href="/" style="margin-left: 3rem;"><img src="../assets/logo-white.png" style="width: 15rem;"/></a>
				<div class="nav-right flex">
					<div class="menu">
						<ul>
							<li v-for="item in this.menuList" :key="item.id">
								<a :href="item.menuUrl?item.menuUrl:'javascript:void(0);'">
									{{item.menuName}}
								</a>
								<div v-if="item.children"  class="sub-navbar">
									<div v-for="item1 in item.children" :key="item1.id">
										<a :href="item1.menuUrl?item1.menuUrl:'javascript:void(0);'">
											{{item1.menuName}}
										</a>
									</div>
								</div>
							</li>
						</ul>
					</div>
					<!-- <div class="language" @click="Language()">
						<span :class="$i18n.locale=='en'?'ac':''">EN</span>
						 / 
						<span :class="$i18n.locale=='zh'?'ac':''">ZH</span>
					</div> -->
					<div class="openMenu" @click="toggleNavbar(true)">
						<img src="../assets/menu.png" >
					</div>
				</div>
		    </div>
		</nav>
		<!-- <div class="customerService flex">
			<img src="../assets/customerService.png" >
		</div> -->
		
		<div class="menuPop" v-if="show" @click="toggleNavbar(false)">
			<ul>
				<li v-for="item in this.menuList" :key="item.id">
					<a :href="item.menuUrl?item.menuUrl:'javascript:void(0);'">
						{{item.menuName}}
					</a>
					<div v-if="item.children">
						<div v-for="item1 in item.children" :key="item1.id">
							<a :href="item1.menuUrl?item1.menuUrl:'javascript:void(0);'">
								{{item1.menuName}}
							</a>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import service from '../utils/request.js';
    export default {
        name: "TopNav",
        props: {
            selected: String,
			fixed:String,
        },
        data() {
            return {
                show: false,
				scroll:'',
				menuList:[]
            }
        },
        methods: {
            toggleNavbar(type) {
                this.show = type;
            },
			Language(){
				if(this.$i18n.locale === 'zh'){
					this.$i18n.locale = 'en'
					localStorage.setItem('lang','en')
				}else{
					this.$i18n.locale = 'zh'
					localStorage.setItem('lang','zh')
				}
			},
			handleScroll(){
				//或者使用document.querySelector('.class或者#id').scrollTop
				this.scroll = document.documentElement.scrollTop||document.body.scrollTop
			}
        },
        components: {
			
		},
		mounted(){
		    // window.addEventListener('scroll',this.handleScroll)
			service.get(`/business/menu/list`).then(res => {
				if(res.data.code == 200){
					res.data.rows.forEach(item=>{
						if(item.parentId==null&&item.isShow==1){
							this.menuList.push(item);
						}else{
							this.menuList.forEach(item1=>{
								if(item.parentId == item1.id && item.isShow==1){
									if(item1.children==null) item1.children = [];
									item1.children.push(item);
								}
							})
						}
					})
				}
			}).catch(err => {
				
			});
		},
    }
</script>

<style lang="scss" scoped>
	.top{background: #fff !important;}
	.navbar{
		padding-top: 2rem;
		padding-bottom: 2rem;
		// background: #fff;
		.nav-link{
			font-weight: normal;
		}
		.nav-right{
			.menu{
				margin-right: 1rem;
				ul{
					display: flex;
					li{
						cursor: pointer;
						padding:0 1rem;
						position: relative;
						a{
							font-size: 1.1rem;
							font-family: MiSans-Bold;
							color: #fff;
							text-decoration: none;
						}
						.sub-navbar{
							display: none;
							position: absolute;
							left: 0;
							right: 0;
							>div{
								margin-top: 10px;
								text-align: center;
								a{
									font-family: MiSans-Medium;
									font-size: 1rem;
								}
							}
						}
						&:hover .sub-navbar{
							display: block;
						}
					}
				}
			}
			.language{
				user-select:none;
				cursor: pointer;
				color: rgba(94, 59, 32, 1);
				.ac{
					color: #ffbe69;
				}
			}
			.openMenu{
				display: none;
				cursor: pointer;
				img{
					width: 20px;
					margin-left:30px;
				}
			}
		}
	}
	
	.customerService{
		position: fixed;
		right: 100px;
		bottom: 100px;
		background: #fff;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		cursor: pointer;
		z-index: 999;
		transition: all 0.3s linear;
		box-shadow: 0 1px 10px #ddd;
		img{
			width: 30px;
			height: 30px;
		}
		&:hover{
			transform: scale(1.1);
		}
	}
	
	
	.menuPop{
		position: fixed;
		width: 100%;
		height: 100vh;
		z-index: 999999;
		ul{
			opacity: 0.95;
			background: #262729;
			padding: 15px 0;
			li{
				padding: 15px 0;
				text-align: center;
				a{
					text-decoration: none;
					color: #fff;
				}
			}
		}
	}
	
	@media (max-width: 568px) {
		.navbar-brand{
			img{
				width: 12rem !important;
			}
		}
		.customerService{
			right: 30px;
			bottom: 30px;
		}
		.openMenu{
			display: block;
		}
	}
	
	@media (max-width: 768px) {
		.menu{
			display: none;
		}
		.navbar{
			.nav-right{
				.openMenu{
					display: block;
				}
			}
		}
	}
	
</style>