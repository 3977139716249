import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Apply from "../views/Apply";
import ContactUs from "../views/ContactUs";
import Jobs from "../views/Jobs";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    meta:{
      title: 'Win Chain'
    },
    component: Index
  },
  {
    path: '/jobs/',
    name: 'Jobs',
    meta:{
      title: 'Jobs'
    },
    component: Jobs
  },
  {
    path: '/contactUs/',
    name: 'ContactUs',
    meta:{
      title: 'ContactUs'
    },
    component: ContactUs
  },
  {
    path: '/apply',
    name: 'Apply',
    meta:{
      title: 'Apply'
    },
    component: Apply
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
