import Vue from 'vue'
import App from './App.vue'
import router from "./router";
// import 'bootstrap/scss/bootstrap.scss'
import './scss/custom.scss';
import './assets/style.css' /*引入公共样式*/
import Dropdown from "bootstrap/js/src/dropdown";
import Button from "bootstrap/js/src/button";
import Carousel from "bootstrap/js/src/carousel";
import Modal from "bootstrap/js/src/modal";
// import Check from "bootstrap/js/src/"

import i18n from './language/i18n.js'

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  Dropdown,
  Button,
  Modal,
  Carousel,
  render: h => h(App),
  created() {
    const html = document.documentElement;
    html.setAttribute('lang', 'en');
  }
}).$mount('#app')


