<template>
	<div id="Labs" :class="isIOS()?'':'backgroundFixed'">
		<div class="container" >
			<a name="LABS"></a>
			<div class="main-title" data-aos='fade-down' style="text-align: center;margin-bottom:20px">
				Calling Founders And Builders
			</div>
			<!-- <div class="main-subtitle" data-aos='fade-down' data-aos-delay='200' style="text-align: center;">
				
			</div> -->
			<div class="flex">
				<a href="/Apply" class="button">
					Apply For Fund
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Labs",
		components: {
			
		},
		data() {
			return {
				ac:'first',
			};
		},
		props: {
			
		},
		mounted() {
			
		},
		methods: {
			isIOS(){
			    var u = navigator.userAgent;
			    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			    return isiOS;
			}
		}
	}
</script>

<style lang="scss" scoped>
	
	#Labs {
	    width: 100%;
	    // min-height: 100vh;
	    // background:#fff url('../assets/bg/AreasOfInterest-bg.png') no-repeat center center;
	    // background-size: cover;
	    display: flex;
	    align-items: center;
		margin-bottom: 12rem;
		.title{
			color:rgba(255, 190, 105, 1);
			font-size: 5rem;
			font-family: Stingray;
		}
		.subtitle{
			color: rgba(166, 114, 71, 1);
			font-size: 1.5rem;
			margin: 3rem 0;
			font-family: BreeSerif-Regular;
		}
		
		.areasInterest{
			display: flex;
			justify-content:center;
			align-items: flex-start;
			flex-wrap: wrap;
			li{
				flex: 0 0 24%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				margin-bottom: 50px;
				cursor: pointer;
				img{
					width: 120px;
					padding: 20px;
					transition:  all 0.3s linear;
				}
				>div{
					margin-top: 10px;
					color: rgba(166, 114, 71, 1);
				}
				.h3{
					font-weight: 500;
					font-size: 3rem;
					color: #cfa346;
					font-family: MiSans-Medium;
				}
				.h4{
					text-align: center;
					font-size: 1.2rem;
					color: #fff;
				}
				&:hover img{
					transform: translateY(-10px) scale(1.1);
				}
			}
		}
	}
	
	@media (max-width: 768px) {
		#Labs{
			.title{
				font-size: 2rem;
			}
			.subtitle{
				font-size: 1.2rem;
			}
			.areasInterest{
				li{
					flex: 0 0 48%;
				}
			}
			.invest{
				padding: 1rem;
				.line-bg{
					display: none;
				}
				.info{
					position: initial;
					width: auto;
					display: flex;
					justify-content: flex-start;
					flex-direction: column;
					margin-bottom: 20px;
					.info-title{
						position: initial;
					}
					.info-introduce{
						position: initial;
						opacity: 1 !important;
						transform: translateY(0) !important;
						.line{
							display: none;
						}
					}
				}
			}
		}
	}
	
	@media (min-width: 768px) and (max-width: 1168px) {
		.container{
			max-width: 720px;
		}
	}
</style>
