<template>
	<div class="app" style="overflow-x: hidden;">
		<div class="loading" v-if="loading">
			<!-- <img src="../assets/logo.svg" > -->
			loading
		</div>
		
		<TopNav selected="Index" fixed="fixed"></TopNav>
		<!-- <Sidebar></Sidebar> -->
		<Funding></Funding>
		<AboutUs></AboutUs>
		<Portfolio></Portfolio>
		<Partners></Partners>
		<Port></Port>
		<OurTeam></OurTeam>
		<Blog></Blog>
		<Labs></Labs>
		<Footer></Footer>
		
		
	</div>
</template>

<script>
	import TopNav from "@/components/TopNav";
	import Footer from "@/components/Footer";
	// import Sidebar from "@/components/Sidebar";
	import Funding from "./Funding";
	import AboutUs from "./AboutUs";
	import Portfolio from "./Portfolio";
	import Partners from "./Partners";
	import Port from "./Port";
	import OurTeam from "./OurTeam";
	import Blog from "./Blog";
	import Labs from "./Labs";
	// import router from "@/router";
	
	import 'aos/dist/aos.css'
	import AOS from 'aos/dist/aos.js'

	export default {
		name: "Index",
		components: {
			TopNav,
			// Sidebar,
			Funding,
			AboutUs,
			Portfolio,
			Partners,
			Port,
			OurTeam,
			Blog,
			Labs,
			Footer,
		},
		data() {
			return {
				loading:false
			};
		},
		props: {
			
		},
		mounted() {
			AOS.init();
			// setTimeout(()=>{
			// 	this.loading = false;
			// },2800)
		},
		methods: {
			
		}
	}
</script>

<style lang="scss">
	@keyframes fadenum{
	   0% {
	   	transform: translateY(0);
	   }
	   50% {
	   	transform: translateY(-1.2rem);
	   }
	   100% {
	   	transform: translateY(0);
	   }
	}
	.loading{
		position: fixed;
		top: 0;
		bottom:0;
		left:0;
		right: 0;
		background: #1f1f26;
		z-index: 99999;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: #ffbe69;
		font-size:1.3rem;
		font-family: BreeSerif-Regular;
		img{
			width: 50px;
			animation:fadenum 2s infinite;
			margin-bottom:20px;
		}
	}
	
	@media (max-width: 768px) {
		.container{
			padding:0 2.5rem !important;
		}
	}
	
</style>
