<template>
	<div>
		<TopNav selected="Index" fixed="fixed"></TopNav>
		<div id="ContactUs">
			<div class="container">
				<div class="main">
					<div class="title">
						{{$t('main.ContactUs')}}
					</div>
					<ul class="form">
						<div>
							For inquiries that cannot be accomplished through warm introductions or direct connections
							with our team, please utilize the "Contact Us" form below.
							Note that the corresponding inboxes are monitored intermittently and only inquiries of high
							urgency or interest will receive a response.
						</div>
						<li>
							<div>Name</div>
							<input type="text" v-model="from.name">
						</li>
						<li>
							<div>{{$t('main.Email')}}</div>
							<input type="text" v-model="from.email">
						</li>
						<li>
							<div>Company</div>
							<input type="text" v-model="from.company">
						</li>
						<li>
							<div>{{$t('main.Message')}}</div>
							<input type="text" v-model="from.message">
						</li>
						<li>
							<div>
								Pictures
							</div>
							<label for="fileinp" class="Pictures">
							    <div v-if="!uploadImgUrl"  class="uploadBtn">upload</div>
								<img v-if="uploadImgUrl||from.picUrl" :src="uploadImgUrl||from.picUrl"
									style="width:100px;height:100px">
							    <input type="file" id="fileinp"  @change="changepic($event)">
							</label>
						</li>
						<div class="button" @click="submint()">
							{{$t('main.send')}}
						</div>
					</ul>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import service from '../utils/request.js';
	import TopNav from "@/components/TopNav";
	import Footer from "@/components/Footer";
	export default {
		name: "ContactUs",
		components: {
			TopNav,
			Footer
		},
		data() {
			return {
				from: {
					name: "",
					email: "",
					company: "",
					message: "",
					picUrl:""
				},
				uploadImgUrl: '',
				uploadImgBlob: ''
			};
		},
		props: {

		},
		mounted() {

		},
		methods: {
			uploadPictures(){
				document.getElementById('fileinp').click();
			},
			submint() {
				if(!this.from.name){
					alert('Please fill in your name first');
					return;
				}
				if(!this.from.email){
					alert('Please fill in your email first');
					return;
				}
				if(!this.from.company){
					alert('Please fill in your company first');
					return;
				}
				service.post(`/business/contact`, this.from).then(res => {
					if (res.data.code == 200) {
						alert('success');
						this.from = {
							name: "",
							email: "",
							company: "",
							message: "",
							picUrl:"",
						};
						this.uploadImgUrl = "";
					}
				}).catch(err => {

				});
			},
			changepic(event) {
				const file = event.target.files[0];
				if (file.type.indexOf("image/") == -1) {
					alert('Error, please upload the image format file');
				} else {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => {
						this.uploadImgUrl = reader.result;
						this.uploadImgBlob = new Blob([file], {
							type: 'image/png'
						});

						let formData = new FormData();
						formData.append("file", this.uploadImgBlob);

						service.post(`/common/upload`, formData).then(res => {
							if (res.data.code == 200) {
								this.from.picUrl = res.data.url;
							}
						}).catch(err => {

						});

					};
				}
			},
			getPicUrl(){
				return window.location.href.indexOf('localhost')!=-1?'http://localhost:8082':'http://admin.dtcapital.xyz';
			}
		}
	}
</script>

<style lang="scss" scoped>
	#ContactUs {
		width: 100%;
		min-height: 100vh;
		display: flex;
		align-items: center;

		.title {
			color: #fff;
			font-size: 4rem;
			font-family: MiSans-Bold;
			padding-bottom: 10rem;
			padding-right: 10rem;
		}

		.main {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			ul {
				width: 50%;
				list-style: none;
				margin: 0;
				padding: 0;
			}

			.information {
				padding-right: 3rem;

				li {
					text-align: right;
					margin-bottom: 2rem;

					.title {
						color: rgba(255, 190, 105, 1);
						font-size: 2rem;
					}

					.subtitle {
						color: rgba(166, 114, 71, 1);
						font-size: 1.2rem;
					}

					img {
						height: 32rem;
					}
				}
			}

			.form {
				font-size: 14px;
				padding-left: 3rem;

				>div {
					margin-bottom: 20px;
					color: #fff;
				}

				li {
					display: flex;
					align-items: center;
					border-bottom: 1px solid rgba(242, 242, 242, 1);
					margin-bottom: 2rem;
					padding: 10px;

					>div {
						color: #fff;
					}

					input {
						border: none;
						outline: none;
						margin-left: 10px;
						background: #000;
						color: #fff;
					}

					&.Upload {
						cursor: pointer;
						border: none;
						justify-content: flex-end;

						img {
							width: 20px;
							margin-left: 10px;
						}
					}
				}

				.button {
					background: #fff;
					color: #000;
					width: 100%;
				}
			}
		}
		
		.Pictures{
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			width: 100px;
			height: 100px;
			border: 1px solid #ddd;
			margin: 20px;
			border-radius: 8px;
			color: #fff;
			input{
				display: none;
			}
		}
	}

	@media (max-width: 768px) {
		#ContactUs {
			.main {
				flex-direction: column;
				.title{
					font-size: 2rem;
					padding: 0;
					margin-bottom: 30px;
				}
				ul {
					justify-content: center;
					width: 100%;

					&.form {
						margin-top: 20px;
						padding-left: 0;
					}
				}
			}
		}
	}
</style>
