<template>
	<div>
		<TopNav selected="Jobs" fixed="fixed"></TopNav>
		<div class="container">
			<div id="Jobs" >
				<div class="left">
					<div class="main-title">
						{{jobData.msgTitle}}
					</div>
					<!-- <div>
						<div>100</div>
						<div>Apply for the fund</div>
					</div> -->
				</div>
				<div class="right">
					<div class="JobsText">
						{{jobData.msgDesc}}
					</div>
					<ul>
						<li v-for="item in tableData" :key="item.id">
							<div class="position">{{item.position}}</div>
							<div class="desc">{{item.jobDesc}}</div>
							<!-- <div class="read"><a :href="`/Apply/${item.id}`">Read more</a></div> -->
						</li>
					</ul>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import service from '../utils/request.js';
	import TopNav from "@/components/TopNav";
	import Footer from "@/components/Footer";
	export default {
		name: "ContactUs",
		components: {
			TopNav,
			Footer
		},
		data() {
			return {
				tableData:[],
				jobData:{},
			};
		},
		props: {
			
		},
		mounted() {
			service.get(`/business/msg/list`).then(res => {
				if(res.data.code == 200){
					if(res.data.rows.length>0){
						this.jobData = res.data.rows[0];
					}
				}
			}).catch(err => {
				
			});
			
			service.get(`/business/job/list`).then(res => {
				if(res.data.code == 200){
					this.tableData  = res.data.rows;
				}
			}).catch(err => {
				
			});
		},
		methods: {
			
		}
	}
</script>

<style lang="scss" scoped>
	#Jobs{
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		.left{
			margin-right: 3rem;
			text-align: right;
			font-size: 14px;
		}
		.right{
			flex:1;
		}
		.JobsText{
			color: #fff;
			width: 70%;
			margin: 15px;
			font-size: 14px;
		}
		ul{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			li{
				flex: 0 0 30%;
				margin: 10px;
				padding: 15px;
				border-radius: 10px;
				background: #646464;
				color: #fff;
				.position{
					color: #fff;
					font-size: 18px;
					margin-bottom: 10px;
				}
				.desc{
					word-break: break-all;
				}
				.read{
					text-align: right;
					margin-top: 10px;
					a{
						text-decoration:none;
						color: rgba(166, 166, 166, 1);
					}
				}
			}
		}
	}
	
	@media (max-width: 768px) {
		#Jobs{
			flex-direction: column;
			.left{
				text-align: center;
			}
			.JobsText{
				width: 90%;
				margin: 15px auto;
			}
			ul{
				li{
					margin: 10px;
					padding: 10px;
					flex: 1;
				}
			}
		}
	}
</style>
