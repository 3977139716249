
export default {
    menu: {
        Identity: 'Identity',
        Approach: 'Approach',
    	Alliance: 'Alliance',
    	Experience: 'Experience',
    	Careers: 'Careers',
    	Contact: 'Contact',
		Leadership:'Leadership',
		Activities:'Activities'
    },
    main:{
    	FundingTitle:'Funding the future tech.',
    	FundingSubTitle:'where we transform your ingenious tech ideas into reality',
		IdentityTitle:'Enabling the Web 3.0',
		IdentitySubTitle:'We are a global venture capital firm with a mission to build and strengthen the next generation of a decentralized Internet, crypto finance, and blockchain technology. WIF has participated in many potential projects in the early stages of  the development of the blockchain industry. ',
		CoreValuesTitle:'Core Values',
		CoreValuesSubTitle:'We establish solid bonds based on mutual respect and trust, as well as productive teamwork. We practice win-win partnerships with our clients and establish long term alliances to work toward success and satisfaction. We value strategic collaboration with people who want to reshape and disrupt technology and make our lives more flexible, simpler, and better. We visualize our future as a decentralized, more transparent network on a global scale running at lightning speed.',
		HowWeInvest:'how we invest',
		HowWeInvestTitle1:'Seed/Startup Stage',
		HowWeInvestTitle2:'Emerging Stage',
		HowWeInvestTitle3:'Expansion Stage',
		HowWeInvestTitle4:'Bridge Stage',
		HowWeInvestText1:'Funding an idea, a devised business plan, and compelling prototypes to fine-tune your products and services and provide further support to business launch.',
		HowWeInvestText2:'Investing in actual product manufacturing and sales, R&D, and marketing to aid high-growth companies and scale into new markets. ',
		HowWeInvestText3:'Backing up companies with exponential growth and injecting additional funds to keep up with shifting demands to enable market expansion and product diversification.',
		HowWeInvestText4:'Sponsor matured companies to support mergers, acquisitions, and IPOs to transform them into full-fledged, viable businesses. ',
		AreasOfInterest:' areas of interest',
		AreasOfInterestTitle:'We invest in smart people or passionate teams solving difficult problems, often related to …',
		AreasOfInterestText1:'Blockchain Tech',
		AreasOfInterestText2:'FinTech',
		AreasOfInterestText3:'Digital Life & Play',
		AreasOfInterestText4:'Social',
		AreasOfInterestText5:'Marketplace & Exchanges',
		AreasOfInterestText6:'Arts & Media',
		AreasOfInterestText7:'Climate Tech',
		ExperienceTitle:'Portfolio',
		ExperienceSubTitle:'We not only have a preeminent team but also help outstanding projects grow together to promote a more prosperous blockchain era.',
		ExperienceText1:'Bitcoin is a decentralized cryptocurrency originally described in a 2008 whitepaper by a person, or group of people, using the alias Satoshi Nakamoto. It was launched soon after, in January 2009.',
		ExperienceText2:'Solana is a highly functional open source project that banks on blockchain technology’s permissionless nature to provide decentralized finance (DeFi) solutions.',
		ExperienceText3:'Polkadot is an open-source sharded multichain protocol that connects and secures a network of specialized blockchains, facilitating cross-chain transfer of any data or asset types, not just tokens, thereby allowing blockchains to be interoperable with each other. Polkadot was designed to provide a foundation for a decentralized internet of blockchains, also known as Web3.',
		ExperienceText4:'Polygon (previously Matic Network) is the first well-structured, easy-to-use platform for Ethereum scaling and infrastructure development. Its core component is Polygon SDK, a modular, flexible framework that supports building multiple types of applications.',
		ExperienceText5:'Flow is a fast, decentralized, and developer-friendly blockchain, designed as the foundation for a new generation of games, apps, and the digital assets that power them.',
		ExperienceText6:'Avalanche is a layer one blockchain that functions as a platform for decentralized applications and custom blockchain networks.',
		ExperienceText7:'Autonomous Network Cross Domain Flexible Sharded System (ANFS) is an efficient, secure and scalable blockchain network. Based on the original Hybrid proof of sequence(HPOS) consensus mechanism, it can improve the overall network operation efficiency on the basis of ensuring complete decentralization.',
		ExperienceText8:'Ocean Protocol is a blockchain-based ecosystem that allows individuals and businesses to easily unlock the value of their data and monetize it through the use of ERC-20 based datatokens.',
		ExperienceText9:'Algorand is a self-sustaining, decentralized, blockchain-based network that supports a wide range of applications.',
		ExperienceText10:'Injective enables access to unlimited decentralized finance markets. Users can create any financial market on Injective’s fast, cross-chain, zero gas fee, secure, and fully decentralized exchange protocol.',
		ExperienceText11:'Decentraland (MANA) defines itself as a virtual reality platform powered by the Ethereum blockchain that allows users to create, experience, and monetize content and applications.',
		Activities_18_11_2021:"India's first Web 3.0 conference",
		Activities_6_7_10_2021:'Asia Disruptive Investment Summit in Decentraland',
		Activities_19_9_2021:'Global Developers Conference',
		Activities_18_8_2021:'BANKIA NIGHT Reciprocal Reception',
		Activities_7_5_2021:'Web3.0 Bootcamp',
		Activities_19_9_2019:'Coimbra 1st Startup Captial Summit',
		Activities_10_8_2019:'Investment forum',
		Activities_12_6_2018:'Entrepreneur Cocktail Party',
		Activities_20_9_2017:'Community Meet Up',
		Activities_13_8_2017:'Technical Seminar',
		ContactTitle:'Reach out to us!',
		ContactSubTitle:'Let us make your ideas real and unlock the potential of future tech.',
		SubmitPitch:'Submit Pitch',
		CareersTitle:'Join our journey',
		CareersSubTitle:'WIF success wouldn’t have been possible without our productive and passionate team. We’re currently expanding and looking for talents who are interested in blockchain technology, cryptocurrencies, decentralized finance, and Web 3.0.',
		Culture:'Culture',
		CultureTitle1:'We all collaborate',
		CultureTitle2:'Great job',
		CultureTitle3:'Diversity. Inclusivity',
		CultureTitle4:'Help you succeed',
		CultureTitle5:'Flexible time',
		CultureText1:'We allow open communication where our ideas, opinions, and needs are welcomed while considering multiple points of view to make more informed decisions.',
		CultureText2:'We praise, reward, and provide incentives for those who excel in their roles. We value our members and make sure they feel appreciated.',
		CultureText3:"We ensure our members of every background have equal opportunities and facilitate an inclusive, welcoming environment in which everyone's ideas matter.",
		CultureText4:'We nurture and empower our teams to achieve their career goals and boost their success. We help them move forward and reach better results.',
		CultureText5:'We promote work-life balance to make time for our family, friends, and loved ones.',
		OpenRoles:'Open Roles',
		OpenRolesName1:'Technical Grants Evaluator',
		OpenRolesName2:'Technical Grants Lead',
		OpenRolesName3:'Business Development Lead',
		OpenRolesName4:'Media Operations Manager',
		APPLYNOW:'APPLY NOW',
		rightPosition:'Don’t see the right position for you?',
		GetInTouch:'Get in Touch',
		HongKong:'HongKong',
		US:'US',
		FullTime:'Full-Time',
		OVERVIEW:'OVERVIEW',
		APPLICATION:'APPLICATION',
		Responsibilities:'Responsibilities',
		Requirements:'Requirements',
		joinUs:'To apply to this position, we invite you to answer a few questions in the application form, and to submit your CV and a cover letter, telling us a bit about yourself and your motivation to join us.',  
		FirstName:'First Name',
		LastName:'Last Name',
		Email:'Email',
		Phone:'Phone',
		Country:'Country',
		LinkedIn:'LinkedIn',
		aboutWIF:'How did you hear about WIF?',
		Message:'Message',
		SubmitApplication:'Submit Application',
		ContactUs:'Contact Us',
		Address:'Address',
		PhoneNumber:'Phone Number',
		EmailUs:'Email Us',
		Upload:'Upload',
		send:'send'
	}
}