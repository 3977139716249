
export default {
    menu: {
        Identity: '身份',
        Approach: '路径',
        Alliance: '联盟',
        Experience: '经历',
        Careers: '职业',
        Contact: '联系',
		Leadership:'领导',
		Activities:'活动',
    },
	main:{
		FundingTitle:'资助未来技术',
		FundingSubTitle:'我们将您巧妙的技术理念转化为现实',
		IdentityTitle:'启用 Web 3.0',
		IdentitySubTitle:'我们是一家全球风险投资公司，其使命是构建和加强下一代去中心化互联网、加密金融和区块链技术。WIF参与了区块链行业发展初期的许多潜在项目。',
		CoreValuesTitle:'核心价值观',
		CoreValuesSubTitle:'我们建立了基于相互尊重和信任以及富有成效的团队合作的牢固纽带。我们与客户建立双赢的合作伙伴关系，并建立长期联盟，努力实现成功和满意。我们重视与那些希望重塑和颠覆技术、让我们的生活更灵活、更简单、更美好的人进行战略合作。我们将我们的未来视为一个去中心化的、更透明的网络，在全球范围内以闪电般的速度运行。',
		HowWeInvest:'我们如何投资',
		HowWeInvestTitle1:'种子/启动阶段',
		HowWeInvestTitle2:'突创阶段',
		HowWeInvestTitle3:'扩张阶段',
		HowWeInvestTitle4:'成熟阶段',
		HowWeInvestText1:'资助一个想法、一个设计好的商业计划和引人注目的雏形，以优化您的产品和服务，并为业务启动提供进一步支持。',
		HowWeInvestText2:'投资于实际的产品制造和销售、研发和营销，以帮助高增长的公司并扩大到新市场。',
		HowWeInvestText3:'支持指数增长的公司，并注入额外资金，以满足不断变化的需求，从而实现市场扩张和产品多样化。',
		HowWeInvestText4:'赞助成熟的公司，以支持兼并、收购和IPO，将其转变为全面发展、可行的企业。',
		AreasOfInterest:'感兴趣的领域',
		AreasOfInterestTitle:'我们投资于聪明的人或热情的团队来解决难题，通常与…',
		AreasOfInterestText1:'区块链技术',
		AreasOfInterestText2:'金融科技',
		AreasOfInterestText3:'数字生活与游戏',
		AreasOfInterestText4:'社交',
		AreasOfInterestText5:'市场和交易所',
		AreasOfInterestText6:'艺术与媒体',
		AreasOfInterestText7:'气候技术',
		ExperienceTitle:'投资组合',
		ExperienceSubTitle:'我们不仅拥有一支卓越的团队，还帮助优秀的项目共同成长，以推动更繁荣的区块链时代。',
		ExperienceText1:'比特币是一种去中心化的加密货币，最初由一个人或一群人使用别名Satoshi Nakamoto在2008年的白皮书中描述。它于2009年1月推出。',
		ExperienceText2:'Solana是一个功能强大的开源项目，利用区块链技术的无许可性质提供去中心化金融（DeFi）解决方案。',
		ExperienceText3:'Polkadot是一个开源的分片多链协议，它连接并保护专门的区块链网络，促进任何数据或资产类型的跨链传输，而不仅仅是令牌，从而允许区块链彼此互操作。Polkadot旨在为区块链的去中心化互联网（也称为Web3）提供基础。',
		ExperienceText4:'Polygon（以前的Matic Network）是第一个结构良好、易于使用的以太坊扩展和基础设施开发平台。其核心组件是Polygon SDK，这是一个模块化、灵活的框架，支持构建多种类型的应用程序。',
		ExperienceText5:'Flow是一个快速、去中心化、对开发者友好的区块链，被设计为新一代游戏、应用程序和为其提供动力的数字资产的基础。',
		ExperienceText6:'Avalanche是一个第一层区块链，用作去中心化应用程序和自定义区块链网络的平台。',
		ExperienceText7:'自治网络跨域灵活共享系统（ANFS）是一个高效、安全和可扩展的区块链网络。基于原始的混合序列证明（HPOS）共识机制，它可以在确保完全分散的基础上提高整体网络运行效率。',
		ExperienceText8:'Ocean Protocol是一个基于区块链的生态系统，允许个人和企业通过使用基于ERC-20的数据代币轻松解锁其数据的价值并将其货币化。',
		ExperienceText9:'Algorand是一个自我维持、去中心化、基于区块链的网络，支持广泛的应用。',
		ExperienceText10:'注入性使人们能够进入无限的去中心化金融市场。用户可以在Injective的快速、跨链、零加油费、安全且完全分散的交换协议上创建任何金融市场。',
		ExperienceText11:'Decentraland（MANA）将自己定义为以太坊区块链支持的虚拟现实平台，允许用户创建、体验内容和应用程序并将其货币化。',
		Activities_18_11_2021:'印度是第一个Web 3.0会议',
		Activities_6_7_10_2021:'Decentraland亚洲破坏性投资峰会',
		Activities_19_9_2021:'全球开发者大会',
		Activities_18_8_2021:'BANKIA NIGHT互惠接待',
		Activities_7_5_2021:'Web3.0训练营',
		Activities_19_9_2019:'科英布拉首届创业资本峰会',
		Activities_10_8_2019:'投资论坛',
		Activities_12_6_2018:'一站式鸡尾酒会',
		Activities_20_9_2017:'社区聚会',
		Activities_13_8_2017:'技术研讨会',
		ContactTitle:'联系我们！',
		ContactSubTitle:'让我们将您的想法变为现实，发掘未来技术的潜力。',
		SubmitPitch:'提交演讲',
		CareersTitle:'加入我们的旅程',
		CareersSubTitle:'WIF的成功离不开我们富有成效和激情的团队。我们目前正在扩展并寻找对区块链技术、加密货币、去中心化金融和Web 3.0感兴趣的人才。',
		Culture:'文化',
		CultureTitle1:'我们都在合作',
		CultureTitle2:'干得好',
		CultureTitle3:'差异包容性',
		CultureTitle4:'帮助您成功',
		CultureTitle5:'灵活的时间',
		CultureText1:'我们接受开放交流，欢迎将我们的想法、意见和需求，同时从各方面考虑，以做出更明智的决定。',
		CultureText2:'我们赞扬、奖励和激励那些在角色上表现出色的人。我们重视我们的会员，并确保他们感到感激。',
		CultureText3:'我们确保所有背景的成员都有平等的机会，并创造一个包容、欢迎的环境，让每个人的想法都很重要。',
		CultureText4:'我們培養並授權團隊實現其職業目標並促進其成功。 我們幫助他們前進並取得更好的結果。',
		CultureText5:'我们促进工作与生活的平衡，为家人、朋友和爱人腾出时间。',
		OpenRoles:'开放职责',
		OpenRolesName1:'技术资助评估员',
		OpenRolesName2:'技术资助负责人',
		OpenRolesName3:'业务开发主管',
		OpenRolesName4:'媒体运营经理',
		APPLYNOW:'立即应用',
		rightPosition:'你看不到合适的位置吗？',
		GetInTouch:'联系我们',
		HongKong:'香港',
		US:'美国',
		FullTime:'完整时间',
		OVERVIEW:'概述',
		APPLICATION:'应用程序',
		Responsibilities:'责任',
		Requirements:'要求',
		joinUs:'为了申请这个职位，我们邀请您回答申请表中的几个问题，并提交您的简历和求职信，告诉我们您的一些情况和加入我们的动机。',  
		FirstName:'名字',
		LastName:'姓',
		Email:'电子邮件',
		Phone:'电话',
		Country:'国家',
		LinkedIn:'领英',
		aboutWIF:'你是怎么听说WIF的？',
		Message:'消息',
		SubmitApplication:'提交申请',
		ContactUs:'联系我们',
		Address:'地址',
		PhoneNumber:'手机号码',
		EmailUs:'向我们发送电子邮件',
		Upload:'上传',
		send:'发送'
		
		
		
	}
}