<template>
	<div id="Apply">
		<div class="Apply-header">
			<a href="/">
				<img src="../assets/logo.png" class="logo">
			</a>
			<div class="title">
				Apply For Fund
			</div>
			<!-- <div class="subtitle flex">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30" fill="none"><defs><rect id="path_0" x="0" y="0" width="30" height="30" /></defs><g opacity="1" transform="translate(0 0)  rotate(0 15 15)"><mask id="bg-mask-0" fill="white"><use xlink:href="#path_0"></use></mask><g mask="url(#bg-mask-0)" ><path id="分组 1" fill-rule="evenodd" style="fill:#A67247" transform="translate(5.14800000000017 3.8100000000001337)  rotate(0 9.806999999999999 11.228250000000001)" opacity="1" d="M0 9.81C0 13.55 2.74 15.93 5.16 18.44C5.99 19.3 8 21.18 9.1 22.18C9.5 22.55 10.12 22.55 10.53 22.18C11.61 21.18 13.63 19.31 14.45 18.44C16.93 15.88 19.61 13.54 19.61 9.81C19.61 4.4 15.22 0 9.81 0C4.4 0 0 4.4 0 9.81Z M17.75 9.804C17.75 5.404 14.2 1.854 9.79997 1.854C5.39997 1.854 1.84997 5.404 1.85997 9.804C1.85997 12.684 3.79997 14.714 5.70997 16.604C6.50997 17.404 8.78997 19.514 9.54997 20.224C9.69997 20.354 9.91997 20.354 10.07 20.224C10.81 19.524 12.99 17.514 13.93 16.594C15.84 14.704 17.76 12.674 17.75 9.804Z " /><path id="分组 2" fill-rule="evenodd" style="fill:#A67247" transform="translate(10.847999999999342 9.078000000000173)  rotate(0 4.109999999999999 4.11)" opacity="1" d="M0 4.11C0 6.37 1.84 8.22 4.11 8.22C6.37 8.22 8.22 6.38 8.22 4.11C8.21 1.84 6.37 0 4.11 0C1.85 0 0 1.84 0 4.11Z M6.472 4.094C6.472 2.794 5.412 1.734 4.112 1.734C2.812 1.734 1.752 2.794 1.752 4.094C1.752 5.394 2.812 6.454 4.112 6.454C5.412 6.454 6.472 5.394 6.472 4.094Z " /></g></g></svg>
				<span class="address" style="margin-right: 30px;">
					{{this.Jobs[this.$route.params.id].address}}
				</span>
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30" fill="none"><defs><rect id="path_0" x="0" y="0" width="30" height="30" /></defs><g opacity="1" transform="translate(0 0)  rotate(0 15 15)"><mask id="bg-mask-0" fill="white"><use xlink:href="#path_0"></use></mask><g mask="url(#bg-mask-0)" ><path id="分组 1" fill-rule="evenodd" style="fill:#A67247" transform="translate(2.6527439428714645 2.6527364355468332)  rotate(0 12.347248564453128 12.347252310791013)" opacity="1" d="M3.61 3.61C-1.2 8.43 -1.2 16.27 3.61 21.08C8.43 25.9 16.27 25.9 21.08 21.08C25.9 16.27 25.9 8.43 21.08 3.61C16.27 -1.2 8.43 -1.2 3.61 3.61Z M19.832 4.86221C15.702 0.742215 8.99204 0.742215 4.86204 4.86221C0.742039 8.99221 0.742039 15.7022 4.86204 19.8322C8.99204 23.9522 15.702 23.9522 19.832 19.8322C23.952 15.7022 23.952 8.99221 19.832 4.86221Z M10.8376 12.8328C10.8376 12.8628 10.8076 13.4328 11.1876 13.8428C11.4376 14.1028 11.7776 14.2328 12.2176 14.2328L18.4876 14.2328C18.9276 14.2328 19.2876 13.8728 19.2876 13.4328C19.2876 12.9928 18.9276 12.6328 18.4876 12.6228L12.4476 12.6228L12.4476 5.94282C12.4476 5.50282 12.0876 5.14282 11.6376 5.14282C11.1976 5.14282 10.8376 5.50282 10.8376 5.94282L10.8376 12.8328Z " /></g></g></svg>
				<span class="time">
					{{this.Jobs[this.$route.params.id].time}}
				</span>
			</div> -->
			<div class="tab">
				<div class="tab-item" :class="tabIndex==0?'ac':''" @click="tabIndex = 0">
					{{$t('main.OVERVIEW')}}
				</div>
				<div class="tab-item" :class="tabIndex==1?'ac':''" @click="goFrom()">
					{{$t('main.APPLICATION')}}
				</div>
			</div>
		</div>
		<div class="container">
			<div class="tab-info" v-if="tabIndex == 0">
				<div class="title">
					Main Point
				</div>
				<div>
					Dear project founders
				</div>
				<div>
					Thank you for your interest in applying for DoubleTree Capital investment. If your project meets our investment criteria, our investment team will contact you directly via our official email: investment@DTCapital.xyz. To better communicate and protect the legitimate rights and interests of both parties, please meet the following requirements when applying for fund:
				</div>
				<ul class="subtitle">
					<li>
						1. All content provided must be true and will not infringe any third party;
					</li>
					<li>
						2. The team has no illegal record;
					</li>
					<li>
						3. The location of the main body of the project is inclusive of web3 business;
					</li>
				</ul>
				<div class="title">
					About Us
				</div>
				<div>
					DTCapital is committed to incubating and investing in outstanding entrepreneurial teams that can quickly and effectively implement technology. From global market empowerment, talent allocation, full-stage financing from the seed round to ecological expansion, and market value management, DT Capital will do its best to provide assistance.
				</div>
				<!-- <div class="flex">
					<div class="button">
						Apply now
					</div>
				</div> -->
			</div>
			<div class="tab-info" v-else>
				<div class="form">
					<div class="form-item form-two">
						<div>
							<div class="title">
								{{$t('main.FirstName')}}
							</div>
							<div>
								<input type="text"  v-model="from.firstName"/>
							</div>
						</div>
						<div>
							<div class="title">
								{{$t('main.LastName')}}
							</div>
							<div>
								<input type="text"  v-model="from.lastName"/>
							</div>
						</div>
					</div>
					<div class="form-item form-two">
						<div>
							<div class="title">
								{{$t('main.Email')}}
							</div>
							<div>
								<input type="text"  v-model="from.email"/>
							</div>
						</div>
						<div>
							<div class="title">
								{{$t('main.Phone')}}
							</div>
							<div>
								<input type="text"  v-model="from.phone"/>
							</div>
						</div>
					</div>
					<div class="form-item form-two">
						<div>
							<div class="title">
								{{$t('main.Country')}}
							</div>
							<div>
								<input type="text"  v-model="from.country"/>
							</div>
						</div>
						<div>
							<div class="title">
								{{$t('main.LinkedIn')}}
							</div>
							<div>
								<input type="text"  v-model="from.linkedIn"/>
							</div>
						</div>
					</div>
					<div class="form-item">
						<div>
							<div class="title">
								{{$t('main.aboutWIF')}}
							</div>
							<div>
								<input type=""  v-model="from.howWif"/>
							</div>
						</div>
					</div>
					<div class="form-item">
						<div>
							<div class="title">
								{{$t('main.Message')}}
							</div>
							<div>
								<input type="text"  v-model="from.comment"/>
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="button" @click="submint()">
							{{$t('main.SubmitApplication')}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import service from '../utils/request.js';
	export default {
		name: "Jobs",
		components: {
			
		},
		data() {
		    return {
				tabIndex:0,
				from:{
					firstName:"",
					lastName:"",
					email:"",
					phone:"",
					country:"",
					linkedIn:"",
					howWif:"",
					comment:"",
				}
		    }
		},
		mounted() {
			
		},
		methods: {
			submint(){
				service.post(`/business/application`,this.from).then(res => {
					if(res.data.code == 200){
						alert('success');
						this.from = {
							firstName:"",
							lastName:"",
							email:"",
							phone:"",
							country:"",
							linkedIn:"",
							howWif:"",
							comment:"",
						};
					}
				}).catch(err => {
					
				});
			},
			goFrom(){
				window.open("https://forms.gle/YY6sMPyBUVXGoj5G9");
			}
		}
	}
</script>

<style lang="scss" scoped>
	#Apply{
		width: 100%;
		min-height: 100vh;
		padding: 5rem 0;
		background: #fff;
		.title{
			color:rgba(0, 0, 0, 1);
			font-size: 2rem;
			// font-family: BreeSerif-Regular;
			margin-bottom: 2rem;
		}
		.subtitle{
			color: rgba(128, 128, 128, 1);
			font-size: 1.5rem;
			margin: 1rem 0;
			svg{
				width: 20px;
			}
		}
		.Apply-header{
			text-align: center;
			.logo{
				width: 200px;
				margin-bottom: 40px;
			}
			.tab{
				padding-top: 1rem;
				border-top: 1px solid rgba(229, 229, 229, 1);
				display: flex;
				justify-content: center;
				align-items: center;
				box-shadow: 0 5px 10px #0000004d;
				.tab-item{
					cursor: pointer;
					color: rgba(0, 0, 0, 1);
					padding: 1rem;
					margin-right: 2rem;
					&.ac{
						border-bottom: 1px solid rgba(0, 0, 0, 1);
					}
					&:last-child{
						margin: 0;
					}
				}
			}
		}
		
		.tab-info{
			padding: 4rem 2rem;
			font-size: 14px;
			.title{
				font-size: 2rem;
			}
			.subtitle{
				font-size: 1.5rem;
			}
			ul{
				li{
					font-size: 14px;
					margin-bottom: 15px;
					img{
						width: 15px;
						margin-right: 10px;
					}
				}
			}
			.form{
				.title{
					font-size: 1.3rem;
					margin-bottom: 10px;
				}
				.form-item{
					margin-bottom: 30px;
				}
				.form-two{
					display: flex;
					>div{
						flex:1;
						&:first-child{
							margin-right: 20px;
						}
						&:last-child{
							margin-left: 20px;
						}
					}
				}
				.Upload{
					margin-bottom: 30px;
					color: rgba(0, 0, 0, 1);
					img{
						width:20px;
						margin-left:10px;
					}
				}
				input{
					border-radius: 3px;
					border: 1px solid rgba(166, 166, 166, 1);
					width: 100%;
					padding: 8px 10px;
					flex:1;
				}
				.button{
					min-width:280px;
					background: rgba(0, 0, 0, 1);
				}
			}
		}
		
	}
	
	
	@media (max-width: 768px) {
		#Apply{
			.tab-info{
				padding: 2rem 1rem;
			}
		}
	}
	
</style>
